import React, { useState, useEffect } from 'react';
import CheckIn from './CheckIn';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { 
    Box, Button, Modal, Typography, createTheme, 
    ThemeProvider, IconButton, 
    Input
} from '@mui/material';
// import { makeStyles } from '@mui/styled-engine'
import { useToast, CircularProgress, InputGroup } from '@chakra-ui/react';
import CloseIcon from '@mui/icons-material/Close';
import { Navigate, useNavigate } from 'react-router-dom';
import Select from 'react-select'; 
import zIndex from '@mui/material/styles/zIndex';

// styling for Modal
const style = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    width: "80vw",
    bgcolor: 'background.paper',
    borderColor: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scoll'
};

const MuiTheme = createTheme({
    palette: {
      mode: "light",
      warning: {main: '#E86224'},

    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
            root: {   
                border: 1,
                borderColor: "#000",
                borderStyle: "solid",
                borderRadius: 5,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                color: "#00005",
                padding: 3,
            },
           
        },
      },
    },
});  


function VisitationPage({visitid, obv, loadingDone}) {

    // prop values
    const visitID = visitid;
    const outOfBoundVisit = obv;
    const userName = useSelector(state=>state.LoginSignupRed.name);
    const userID = useSelector(state=>state.LoginSignupRed.password);  
    const userLocation = useSelector(state=>state.UserLocationRed.geolocation);
    const userGeoAddress = useSelector(state=>state.UserLocationRed.geoAddress);
    const isUserAdmin = useSelector(state=>state.LoginSignupRed.isAdmin);

    // toasty for notifications
    const toast = useToast();

    //navigation routing component
    const nav = useNavigate();

    // state variables
    const [businessName, setBusinessName] = useState("");
    const [profile, setProfile] = useState("");
    const [farmAddress, setFarmAddress] = useState("");
    const [province, setProvince] = useState("");
    const [newBusiness, setNewBusiness] = useState(false);
    const [cellNumber, setCellNumber] = useState("");
    const [email, setEmail] = useState("");
    const [reasonFVisit, setReasonFVisit] = useState('');
    const [comments, setComments] = useState('');
    const [foContactName, setFoContactName] = useState("");
    const [foContactSurname, setFoContactSurname] = useState("");
    const [farmName, setFarmName] = useState("");
    const [farmType, setFarmType] = useState("");
    const [farmId, setFarmID] = useState(0);
    const [additionalNumber, setAdditionalNumber] = useState("");
    const [leaseType, setLeaseType] = useState("");
    const [suInProg, setSip] = useState("");
    const [open, setOpen] = useState(false)

    const resetForm = () => {
        setBusinessName("");
    }

    //clear state function
    const resetState = () => {
        setBusinessName("");
        setProfile("");
        setFarmAddress("");
        setProvince("");
        setCellNumber("");
        setEmail("");
        setReasonFVisit("");
        setComments("");
        setFarmID(0);
        setFarmType("");
        setFarmName("");
        setFarmAddress("");
        setFoContactName("");
        setAdditionalNumber("");
        setLeaseType("");
        setNewBusiness(false);
        setFoContactSurname("");
        setSip("");
    }


    useEffect(() => {
        if(visitID !== undefined && outOfBoundVisit !== true){
            getVisitDetails(visitID);
        }
        else {
            setOpen(true);
        }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitID, outOfBoundVisit]);


    const handleClose = () => {
        setOpen(false);  
        nav("/")
    }


    const getVisitDetails = async () => {

        try {

            fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/visitation/${visitID}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((res) => res.json())
            .then((data) => {
                setBusinessName(data[0].business_name);
                setProfile(data[0].farm_profile);
                setFarmAddress(data[0].address);
                setProvince(data[0].province);
                setAdditionalNumber(data[0].additional_tel_no);
                setEmail(data[0].email);
                setCellNumber(data[0].phone);
                setFarmType(data[0].farm_type);
                setFoContactName(data[0].contact_name);
                setFoContactSurname(data[0].client_surname);
                setFarmName(data[0].farm_name);
                setComments(data[0].farm_comments);
                // console.log(data)
            })
            .finally(() => {
                setOpen(true)
            })

            
        } catch (error) {
            console.error("Error: ", error.message)
        }

    }


    const handleFormSubmit = (e) => {

        e.preventDefault();

        setSip("Pending");

        // check if business name is the same as farm name
        if(businessName.toLowerCase() === farmName.toLowerCase()) {

            return window.alert("Oops!! Business Name and Farm Name cannot be the same. Please enter a valid farm name and resubmit.")
        }

        let contactOwner;
        let allowNoAddress;
        let defaultAddress = {address: '20 Station St, Swellendam, 6740'};
        let defaultLocation = {lat: -34.02693430123456, lon: 20.44368273175919};
        let overrideAddress = false;

        if(leaseType === "rental") {
            contactOwner = false;
        }
        else {
            contactOwner = true;
        }

        if(userLocation === undefined) {
            
            allowNoAddress = window.confirm('WARNING: You are about to complete the visitation without checking in. Click OK to continue');

            if(allowNoAddress) {

                window.alert(`Defaulting check in address to ${defaultAddress.address} | lat:${defaultLocation.lat}, lon:${defaultLocation.lon}`);

                overrideAddress = true;
            }

            else {
                return
            }
        }

        let formData = {
            'farmName': farmName,
            'businessName': businessName, 'farmType': farmType, 'contactOwner': contactOwner, 
            'profile': profile, 'email': email, 'Address': farmAddress,
            'phone':cellNumber, 'additionalTel': additionalNumber, 'province': province,
            'visitReason': reasonFVisit, 'comments': comments,
            'userId': userID, 'username': userName, 'coords': userLocation,
            'geoAddress': userGeoAddress, 'farmId': farmId, 'contactName': foContactName, 
            'contactSurname': foContactSurname, 'newBusiness': newBusiness, 'overrideAddress': overrideAddress
        }

        // warn user about missing or incorrect values

        if(businessName === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not selected a BUSINESS NAME. Please check your form input.");
        }

        if(farmType === ''  || farmType === 'UNDEFINED') {
            setSip("");

            return window.alert("Oops! Looks like you have not selected a FARM PROFILE. Please check your form input.");
        }

        if(profile === '' || profile === 'UNDEFINED') {
            setSip("");

            return window.alert("Oops! Looks like you have not selected a FARM TYPE. Please check your form input.");
        }

        if(reasonFVisit === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered a REASON FOR VISIT. Please check your form input.");
        }

        if(foContactName === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered a CONTACT PERSON. Please check your form input.");
        }

        if(foContactSurname === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered the CONTACT PERSON SURNAME. Please check your form input.");
        }

        if(cellNumber === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered a CONTACT NUMBER. Please check your form input.");
        }

        if(farmId === 0 || null) {
            setSip("");

            return window.alert("Oops! Farm ID is incorrect. Please select the correct farm");
        }

        if(farmName === '' || null) {
            setSip("");

            return window.alert("Oops! Farm Name is incorrect. Please enter the correct farm name");
        }

        let confirmation;

        // console.log(formData);
        if(businessName !== '' && farmType !== "") {
            confirmation = window.confirm(`
            Confirm:
            \nFarm Name:${farmName} | New Business:${newBusiness}| Business Name:${businessName} | Lease Type:${leaseType} | Farm Type:${farmType} | Profile:${profile} | Farm Address:${farmAddress} | Contact Name:${foContactName} | Cell Number:${cellNumber} | Additional Contact:${additionalNumber}| Email:${email} | Visit Reason: ${reasonFVisit} | Comments: ${comments}
            `);
        }
        else {
            window.alert("Oops!! Form is incomplete.");
        }
        

        if(confirmation && !outOfBoundVisit) {

            fetch("https://jdi.market.geoint.africa/jdipwa/api/farm/edits", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then((result) => {
                // console.log(result)
                if(result.success) {

                    resetState();

                    return toast({
                        position: 'bottom-right',
                        title: result.message,
                        // description: "Welcome to MapstackUJ.com",
                        status: 'success',
                        duration: 2000,
                        isClosable: false,
                    });
                }
                else{
                    resetState();

                    return toast({
                        position: 'bottom-right',
                        title: 'Farm update submission was not successful. Please try again.',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error.message);
            })

            // posting to visits first so that you can use the business name as a target when populating the goelocation
            // and the visit_id to the 
            setTimeout(() => {
                fetch("https://jdi.market.geoint.africa/jdipwa/api/visits", {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(formData)
                })
                .then(res => res.json())
                .then((result) => {
                // console.log(result)

                if(result.success) {
                    return toast({
                        position:'bottom-right',
                        title: 'Farm visitation form submitted successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                }
                else{
                    return toast({
                        position: 'bottom-right',
                        title: 'Visitation form submission was not successful. Please try again.',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                console.error('error:', error.message)
            })
            .finally(() => {
                //go back to main page
                nav('/farms')
            });
            //timeout in ms defined beflow
            }, 3000);
        }
        else {
            fetch("https://jdi.market.geoint.africa/jdipwa/api/farm/obv", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then((result) => {
                // console.log(result)
                if(result.success) {

                    resetState();

                    return toast({
                        position: 'bottom-right',
                        title: result.message,
                        // description: "Welcome to MapstackUJ.com",
                        status: 'success',
                        duration: 2000,
                        isClosable: false,
                    });
                }
                else{
                    resetState();

                    return toast({
                        position: 'bottom-right',
                        title: 'Farm update submission was not successful. Please try again.',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error.message);
            })

            // posting to visits first so that you can use the business name as a target when populating the goelocation
            // and the visit_id to the 
            setTimeout(() => {
                fetch("https://jdi.market.geoint.africa/jdipwa/api/obv/visits", {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(formData)
                })
                .then(res => res.json())
                .then((result) => {
                // console.log(result)

                if(result.success) {
                    return toast({
                        position:'bottom-right',
                        title: 'Farm visitation form submitted successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                }
                else{
                    return toast({
                        position: 'bottom-right',
                        title: 'Visitation form submission was not successful. Please try again.',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                console.error('error:', error.message)
            })
            .finally(() => {
                //go back to main page
                nav('/farms')
            });
            //timeout in ms defined beflow
            }, 3000);
        }
    }

    return (
        <ThemeProvider theme={MuiTheme}>

        <Container fluid="true" style={{zIndex: 999}}>
            {open ?
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflowY: 'scroll'}}
            >
                <Box sx={style}>
                    <IconButton aria-label="close" size="large" onClick={handleClose} style={{left: "80%", top: "0"}}>
                        <Typography variant="body2" component="p">Cancel</Typography>
                        
                        <CloseIcon fontSize="inherit" />
                    </IconButton>

                    <h5><b>Check In</b></h5>
                    <Row>
                        <Col>
                            <CheckIn />
                        </Col>
                        <Col>
                            {/* SPACER */}
                        </Col>
                        <Col>
                            {/* SPACER */}
                        </Col>
                    </Row>    
                    <hr></hr>
                    <h5><b>Farm Visitation </b></h5>
                        <br/>
                        <br/>
                        <form autoComplete="off" onSubmit={(e) => handleFormSubmit(e)} >
                            <Button 
                                    size='small' 
                                    type='reset' 
                                    onClick={resetForm} 
                                    style={{color:'orangered', borderRadius:'3px'}}
                            >
                                    reset 
                            </Button>

                            <br/>
                            <br/>
                            <label for="clientsSelect">Business Name *</label>
                            <Input
                                onChange={e => setBusinessName(e.target.value)}
                                // defaultInputValue={ businessName }
                                value={ businessName }
                                style={{
                                    width: "100%",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                }}
                                id="clientsSelect"
                                required
                            >
                                {businessName}
                            </Input>
                            <label for="farmAddress">Business Physical Address</label>
                            {
                                farmAddress !== "" 
                                
                                ? (
                                    <Input
                                        id="existingFarmAddress"
                                        value={farmAddress}
                                        onChange={e => setFarmAddress(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "6px 10px",
                                            margin: "8px 0",
                                            display: "inline-block",
                                            border: "1px solid #E86222",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                    )
                                :
                                    <></>
                            }   
                            <label for="farm_name">Farm Name</label>
                            <input 
                                onChange={e => setFarmName(e.target.value)}
                                value={farmName}
                                type='text'
                                style={{
                                    width: "100%",
                                    display: "inline-block",
                                    padding: "6px 10px",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                    margin: "8px 0",
                                }}
                                id='farm_name'
                            />
                            <br/>             
                            <label for="leaseType">Farm Lease Type</label>
                            <select 
                                id="leaseType" 
                                name="leaseType" 
                                placeholder='Farm lease type' 
                                onChange={e => setLeaseType(e.target.value)}
                                style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",    
                                }}
                                // onBeforeInput={setLeaseType(rowParams.row.contact_owner)}
                            >
                                <option value={"owner"}
                                >
                                    Owner
                                </option>
                                <option value="rental">Rental</option>
                                <option value="owner">Owner</option>
                            </select>
                            <label for="visitationFreq">Farm Type *</label> {/* AKA profile state value in app*/}
                            <select 
                                id="visitationFreq" 
                                name="visitationFreq" 
                                placeholder='Visitation Frequency' 
                                onChange={e => setProfile(e.target.value)}
                                // onBeforeInput={setProfile(rowParams.row.farm_type)}
                                style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                        
                                }}
                                required
                            >
                                <option value={profile === null
                                    ? "UNDEFINED"
                                    : profile }
                                >
                                    {profile}
                                </option>
                                <option value="A">A - Monthly visitation</option>
                                <option value="B">B - Visitation every 3 months</option>
                                <option value="C">C - Visitation every 6 months</option>
                                <option value="D">D - Visitation every 12 months</option>
                                <option value="E">E - Visitation every 18 months</option>
                            </select>
                            <br />
                            <label for="profile">Farm Profile *</label> {/* AKA farm_type */}
                            <select 
                                id="profile" 
                                name="profile" 
                                placeholder='Select profile' 
                                onChange={e => setFarmType(e.target.value)}
                                // onBeforeInput={setFarmType(rowParams.row.farm_profile)}
                                style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                        
                                }}
                                required
                            > 
                                <option value={farmType === null 
                                    ? "UNDEFINED"
                                    : farmType
                                }>{farmType}</option>
                                <option value="JDI">Customer only has JDI equipment</option>
                                <option value="JDI/OPPO">Customer has JDI equipment as well as competitor brands</option>
                                <option value="OPPO">Customer does not have any JDI equipment</option>
                                <option value="NONE">Customer does not have any equipment </option>
                            </select>
                            <br />
                            <label for="reason">Reason for visit *</label>
                            <br />
                            <select id="reason" name="reason" placeholder='Select Reason' onChange={e => setReasonFVisit(e.target.value)}
                                style={{
                                        width: "100%",
                                        padding: "10px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "2px solid #13ab4b",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                }}
                                required
                            >
                                <option value="None">Select </option>
                                <option value="Courtesy">Courtesy</option>
                                <option value="Quotation">Quotation</option>
                                <option value="Delivery">Delivery</option>
                                <option value="Inspection">Inspection</option>
                                <option value="Problem">Problem</option>
                                <option value="Updating Farm Information">Updating Farm Information</option>
                            </select>
                            <br />
                            <label for="fo_name">Business Contact Person *</label>
                            <input 
                                onChange={e => setFoContactName(e.target.value)}
                                value={foContactName}
                                style={{
                                    width: "100%",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                }}
                                type='text'
                                id='fo_name'    
                                required                            
                            />
                            <label for="fo_surname">Business Contact Surname *</label>
                            <input 
                                onChange={e => setFoContactSurname(e.target.value)}
                                // onBeforeInput={setFoContactName(rowParams.row.client_surname)}
                                value={foContactSurname}
                                style={{
                                    width: "100%",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                }}
                                type='text'
                                id='fo_surname'
                                required                          
                            />


                            <label for="cell">Business Contact Person Cellphone *</label>
                            <input 
                                label="Cell number"
                                onChange={e => setCellNumber(`${e.target.value}`)}
                                style={{
                                    width: "100%",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                }}
                                type="number"
                                id="cell"
                                placeholder="e.g) 083555999 (Digits Only)"
                                value={cellNumber}
                                required
                            />
                            <label for="additionalNumber">Additional Contact Number</label>
                            <input 
                                label="Additional Number"
                                onChange={e => setAdditionalNumber(`${e.target.value}`)}
                                style={{
                                    width: "100%",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                }}
                                type='number'
                                id="additionalNumber"
                                placeholder="e.g) 083555999 (Digits Only)"
                                value={additionalNumber}

                            />
                            <label for="fo_email">Business Email</label>
                            <input 
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                type='email'
                                style={{
                                    width: "100%",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                }}
                                id='fo_email'
                            />
                            <br />
                            <label for="comments">Comments</label>
                            <br />
                            <textarea
                                onChange={e => setComments(e.target.value)}
                                value={comments}
                                type='text'
                                style={{
                                    width: "100%",
                                    height: "50px",
                                    padding: "6px 10px",
                                    margin: "8px 0",
                                    display: "inline-block",
                                    border: "1px solid #E86222",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                    fontFamily:"sans-serif",
                                    fontSize:"1.0em"
                                }}
                            />
                            <br />
                            <Row>
                                <Col>
                                    
                                        
                                        <Button variant="contained" style={{width: "100%", padding: "5px"}} type="submit" onClick={(e) => {
                                            // setFarmID(rowParams.row.farm_id)
                                            // setBusinessName(businessName !== "" ? businessName : rowParams.row.business_name)
                                            // setFarmAddress(farmAddress !== "" ? farmAddress : rowParams.row.postal_address)
                                            // setCellNumber(cellNumber !== "" ? cellNumber : rowParams.row.cell_no)
                                            // setEmail(email !== "" ? email : rowParams.row.email)
                                            // setTelephone(telephone !== "" ? telephone : rowParams.row.telephone)
                                            // setProvince(province !== "" ? province : rowParams.row.province)  
                                            
                                            handleFormSubmit(e)                                        
                                        }} 
                                        >
                                            {suInProg === "Pending" ?
                                            <>Sending</>

                                            :

                                            <>Submit</>
                                            }
                                        </Button>
                                </Col>
                                <Col>
                                    <Button variant="contained" style={{width: "100%", backgroundColor:"darkorange"}} onClick={handleClose}>
                                        cancel
                                    </Button>
                                </Col>
                            </Row>      
                    </form>
                </Box>
            </Modal> 
            : null
            }
        </Container>
        </ThemeProvider>

    )
}

export default VisitationPage;